import React from 'react';
import {PageProps} from "gatsby";
import {EmbedLayout} from "../../containers/EmbedLayout";
import {HSForm, HSFormTestId} from "../../components/HSForm";

type GetBetaFormDataType = [{name: string, value: string}] | {};

export type GetBetaProps = {} & PageProps;
export default function GetBeta({...props}: GetBetaProps) {
    // TODO the getBeta form needs text and styling!
    return <EmbedLayout {...props} meta={{title: 'Get Full Version'}}>
        <div className={'p-m-3'}>
            <h4>Test form</h4>
            <HSForm<GetBetaFormDataType> formId={HSFormTestId} onSubmit={onSubmit}/>
            {/*<HSForm formId={'aeb04e14-6696-43ef-8895-1bc2b2a2dbc0'}/>*/}
        </div>
    </EmbedLayout>;
}

function onSubmit(data: GetBetaFormDataType) {
    if (Array.isArray(data)) {
        const email = data.find(entry => entry.name === 'email')?.value;
        if (email) {
            window.FlutterApp?.postMessage(JSON.stringify({event: 'getBetaSignup', email: email}))
        }
    }
}
